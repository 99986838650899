// 省份对应的拼音
const nameToPinyin = {
  安徽省: 'anhui',
  澳门特别行政区: 'aomen',
  北京市: 'beijing',
  福建省: 'fujian',
  甘肃省: 'gansu',
  广东省: 'guangdong',
  广西壮族自治区: 'guangxi',
  贵州省: 'guizhou',
  海南省: 'hainan',
  河北省: 'hebei',
  河南省: 'henan',
  黑龙江省: 'heilongjiang',
  湖北省: 'hubei',
  湖南省: 'hunan',
  吉林省: 'jinlin',
  江苏省: 'jiangsu',
  江西省: 'jiangxi',
  辽宁省: 'liaoning',
  内蒙古自治区: 'neimenggu',
  宁夏回族自治区: 'ningxia',
  青海省: 'qinghai',
  山东省: 'shandong',
  山西省: 'shanxi',
  陕西省: 'shanxi1',
  上海市: 'shanghai',
  四川省: 'sichuan',
  台湾省: 'taiwan',
  天津市: 'tianjin',
  西藏自治区: 'xijiang',
  香港特别行政区: 'xianggang',
  新疆维吾尔自治区: 'xinjiang',
  云南省: 'yunnan',
  浙江省: 'zhejiang',
  重庆市: 'chongqing'
}

// 获取省份名称及地图矢量数据的地址
export function getProvinceMapInfo(arg) {
  // 为各省份json数据
  const path = `/src/assets/js/province/${nameToPinyin[arg]}.json`
  return {
    key: nameToPinyin[arg],
    path: path
  }
}
